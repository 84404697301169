<template>
  <b-container fluid>
    <b-row style="height: 100vh">
      <b-col md="5" lg="4" class="p-0 d-none d-md-block">
        <SideBarComponent />
      </b-col>
      <b-col md="7" lg="8" class="">
        <div class="wrapper__header-sign" :style="bgImageSign">
          <LogoComponent />
          <p>{{ `Services & Solutions` }}</p>
        </div>
        <main class="d-flex align-items-center flex-column">
          <div class="w-100 wrapper__body-sign">
            <h1 class="h1 text-md-left font-semi-bold mb-2r">
              ¡Hey! <img :src="icoHey" class="pl-0" alt="" width="36" />
            </h1>

            <el-form :model="formLogin" :rules="rules" ref="formLogin">
              <el-form-item prop="email">
                <el-input
                  v-model="formLogin.email"
                  placeholder="email@email.com"
                  autocomplete="on"
                  name="email"
                ></el-input>
              </el-form-item>

              <el-form-item prop="password" class="mt-4">
                <el-input
                  v-model="formLogin.password"
                  type="password"
                  show-password
                  placeholder="contraseña"
                  autocomplete="on"
                  name="password"
                ></el-input>
              </el-form-item>

              <div class="wrapper__remember-password">
                <router-link
                  class="a-link d-block"
                  :to="{ name: 'recovery-password' }"
                >
                  ¿Olvidaste tu contraseña?
                </router-link>
              </div>

              <div class="form-group text-center">
                <button
                  class="btn btn-lg md__btn-primary btn-block px-5 my-4"
                  @click.prevent="onSubmit()"
                >
                  Iniciar sesión
                </button>
              </div>

              <!-- <div class="d-flex justify-content-center">
                <p>ó</p>
              </div>
              <div class="d-flex justify-content-around btn-social-medias">
                <button class="btn btn-google px-4 py-2 text-white btn-block">
                  <img :src="icoGoogle" alt="icono google" />
                  <p>Ingresar con Google</p>
                </button>
              </div> -->
            </el-form>
          </div>

          <div class="wrapper__register">
            <span class="text-color-black">¿No tienes cuenta? </span>
            <router-link class="a-link" :to="{ name: 'register' }">
              <span class="text-color-primary font-bold">Registrate</span>
            </router-link>
          </div>
        </main>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  myDesskBlack,
  icoGoogle,
  icoHey,
  bgFigure
} from "@/services/resources";
import SideBarComponent from "../../components/SideBar/SideBar";
import LogoComponent from "@/components/Logo/Logo";
import Validate from "@/assets/validate";

export default {
  name: "LoginPage",
  data: () => ({
    myDesskBlack,
    icoGoogle,
    icoHey,
    formLogin: {
      email: "",
      password: ""
    },
    rules: {
      email: [{ ...Validate.rules.required(), ...Validate.rules.email() }],
      password: [Validate.rules.required()]
    },
    bgImageSign: {
      backgroundImage: `url(${bgFigure})`
    }
  }),
  methods: {
    onSubmit() {
      this.$refs.formLogin.validate(async valid => {
        if (valid) {
          try {
            let response = await this.$store.dispatch("login", this.formLogin);
            const { success, message } = response.data;
            if (success) window.location.reload();
            else
              this.$notifications.error({
                message
              });
          } catch (error) {
            return false;
          }
        }
      });
    }
  },
  components: {
    SideBarComponent,
    LogoComponent
  },
  props: ["query"],
  mounted() {
    if (this.query && this.query.email) this.formLogin.email = this.query.email;
  }
};
</script>

<style scoped lang="scss">
@import "./Login.scss";
</style>
